<template>
<div>
  <div class="searchBox">
    <div>
      <div class="inputBox borderno">
         <!-- @input="SearchClick()"  -->
        <el-input v-model="value" placeholder="搜地点"/>
        <span @click="SearchClick()">
          <i class="el-icon-search"></i>
        </span>
      </div>
      <div
        v-if="SearchResultArray && SearchResultArray.length > 0"
        class="SearchResultDataClass"
      >
        <div
          v-for="(item, index) in SearchResultArray"
          :key="index"
          class="ResultItem"
          @click="SearchItemClick(item)"
        >
          <i class="el-icon-location-outline"></i>
          <div class="SearchFont" :title="item.district + item.name">
            {{ item.district }}{{ item.name }}
          </div>
        </div>
        <div class="hide" @click="SearchCancel">收起</div>
      </div>
    </div>
    <div class="branch borderno">
      <div class="dq">
        {{ lnglat }}
      </div>
    </div>
    <!-- <div class="branch borderno">
      <div class="dq">
        <el-select v-model="regionValue" placeholder="请选择行政区" clearable @change="regoinChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="`${item.label}|${item.value}`"
          >
          </el-option>
        </el-select>
      </div>
    </div> -->
    
    </div>
    <div class="simulation">
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        placement="right-start"
      >
        <li slot="reference" @click="ClickLeftMenu(3)">
          <i class="el-icon-copy-document"></i>图层
        </li>
      </el-popover>
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        visible-arrow="false"
        v-model="visible"
      >
        <li slot="reference" @click="ClickLeftMenu(1)">
          <i class="el-icon-search"></i>事件
        </li>
      </el-popover>
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        visible-arrow="false"
        v-model="visible"
      >
        <li slot="reference" @click="ClickLeftMenu(2)">
          <i class="el-icon-search"></i>人员
        </li>
      </el-popover>
      <div
        class="LeftFunction"
        :style="{ width: modeal ? '150px' : '300px' }"
        v-if="SelectMenuData == 3"
      >
        <div class="popoverCon">
          <el-checkbox-group
            v-if="modeal"
            v-model="checkedLayers"
            @change="selectLayer"
          >
            <el-checkbox
              v-for="layer in baseLayers"
              :label="layer.value"
              :key="layer.value"
              >{{ layer.name }}</el-checkbox
            >
          </el-checkbox-group>
          <el-tree
            v-else
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree"
            :default-expanded-keys="openKeys"
            :default-checked-keys="checkLayer"
            :default-expand-all="isExpand"
            :props="defaultProps"
            @check="checkClick"
          >
          </el-tree>
        </div>
      </div>
      <div class="LeftFunction" v-if="SelectMenuData == 1">
        <el-input
          clearable
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="EventAjaxData.input"
          size="mini"
          style="width: 60%; margin-right: 10px"
        >
        </el-input>
        <el-button @click="SearchDataFun" size="mini">查询</el-button>

        <el-date-picker
          :append-to-body="appendToBodyBool"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="datePicker"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 100%; margin-top: 10px; margin-bottom: 10px"
          size="mini"
          @change="DateChange"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        事件类型:
        <el-cascader
          :append-to-body="appendToBodyBool"
          size="mini"
          style="width: 180px; margin-left: 20px"
          v-model="EventTypevalue"
          :options="EventTypeList"
          @change="EventTypeChange"
          :props="{ children: 'children', label: 'name', value: 'id' }"
          clearable
        ></el-cascader>
        <div style="height: 300px; overflow: auto" class="EventItemBody">
          <div
            v-for="item in EventList"
            @click="EventClickFun(item)"
            :key="item.id"
            class="EventItemClass"
          >
            <div style="font-size: 14px">
              事件类型：{{ item.eventCodeName }}
            </div>
            <div style="font-size: 14px">
              事件状态：{{ item.eventResultsName }}
            </div>
            <div style="font-size: 14px; margin-top: 5px">
              {{ item.eventAddress }}
            </div>
            <div class="EventItemBottom">
              <label style="color: #999; font-size: 12px">
                <i class="iconfont icon-renyuan"></i>
                {{ item.createBy }}
              </label>
              <label style="color: #999; font-size: 12px">
                <i class="iconfont icon-rili"></i>
                {{ item.createTime }}
              </label>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center">
          <el-pagination
            style="margin-bottom: 0px"
            @current-change="EventPageCurrentChange"
            layout="prev, pager, next"
            :pager-count="5"
            :total="EventTotal"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <div class="LeftFunction" v-if="SelectMenuData == 2">
        <el-input
          clearable
          placeholder="请输入人员姓名"
          prefix-icon="el-icon-search"
          v-model="PersonAjaxData.name"
          size="mini"
          style="width: 70%; margin-right: 10px"
        >
        </el-input>
        <el-button @click="SearchPersonFun" size="mini">查询</el-button>
        <div style="height: 300px; overflow: auto" class="EventItemBody">
          <div
            v-for="item in PersonList"
            @click="PersonClickFun(item)"
            :key="item.id"
            class="EventItemClass"
          >
            <div style="font-size: 14px">姓名：{{ item.username }}</div>
            <div style="font-size: 14px">手机号：{{ item.mobile }}</div>
            <div style="font-size: 14px">
              状态：{{ item.online == 0 ? "在线" : "离线" }}
            </div>
            <div style="font-size: 14px; margin-top: 5px">
              {{ item.eventAddress }}
            </div>
            <div class="EventItemBottom"></div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { regionList } from "@/apis/geo";
import { eventList, personList } from "@/RequestPort/maintenance/task";
import { dicByType, patrolGetDict } from "@/RequestPort/maintenance";
export default {
  name: "Tool",
  components: {},
  props: {
    modeal: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lnglat:'',
      appendToBodyBool: false,
      options: [],
      regionValue: "",
      value: "",
      SearchResultArray: [],
      marker: null,
      hasMarker: false,
      postionVal: 4.62,
      geojson: null,
      weather: "",
      layers: [],
      layerName: "",
      lastArr: [],
      autoComplete: null,
      // checkedLayers:["'patrol_events'","'patrol_equipments'","'patrol_lines'","'patrol_networks_jihe'","'user'"],
      checkedLayers: [],
      baseLayers: [
        // {value:'',name:'事件'},{value:'',name:'人员'},
        { value: "'patrol_lines'", name: "管线" },
        { value: "'patrol_events'", name: "事件" },
        { value: "'user'", name: "人员" },
        { value: "'patrol_equipments'", name: "设备" },
        // { value: "'patrol_networks_jihe'", name: "片区" },
      ],
      // 树
      data: [],
      // 展开不展开
      isExpand: true,
      // 对应的值
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
      },
      openKeys: [],
      checkLayer: [],
      visible: false,
      SelectMenuData: null,
      SearchEvent: {},
      EventArray: [],
      datePicker: "",
      EventAjaxData: {
        address: "",
        endTime: "",
        input: "",
        startTime: "",
        current: 1,
        size: 100,
        eventType: "",
      },
      PersonAjaxData: {
        name: "",
        onlineState: "",
        trackState: "",
        current: 1,
        size: 100000,
      },
      PersonList: [],
      EventList: [],
      EventTypeList: [],
      EventTypevalue: "",
      EventTotal: 0,
    };
  },
  computed: {},
  watch: {
    SelectMenuData() {
      this.$parent.map.remove(this.$parent.markerHighLight);
      if (this.$parent.userpolyline) {
        this.$parent.map.remove(this.$parent.userpolyline);
      }
      if (this.$parent.labelsLayerUser) {
        this.$parent.map.remove(this.$parent.labelsLayerUser);
      }
    },
  },
  destroyed() {
    this.$eventBus.$off("addEquipment");
    this.$eventBus.$off("setLnglat");
  },
  mounted() {
    
    this.$eventBus.$on("setLnglat", (data) => {
      this.setLnglat(data);
    });
    //  this.getRegion()
    // 点击添加设备的时候自动展示管线
    this.$eventBus.$on("addEquipment", (data) => {
      let index = this.checkedLayers.findIndex((el) => el == "patrol_lines");
      if (index < 0) {
        this.checkedLayers.push("'patrol_lines'");
      }
      let number = this.checkedLayers.findIndex((el) => el == "patrol_equipments");
      if (number < 0) {
        this.checkedLayers.push("'patrol_equipments'");
      }
      let layer = this.checkedLayers.toString();
      this.$eventBus.$emit("wmsLayer", layer);
    });
    const nowInWhere = window.location.hash;
    if (nowInWhere.indexOf("myEquipment") != -1) {
      this.checkedLayers = [
        "'patrol_equipments'",
        // "'patrol_networks_jihe'"
      ];
    } else if (nowInWhere.indexOf("myLine") != -1) {
      this.checkedLayers = [
        "'patrol_lines'",
        // "'patrol_networks_jihe'"
      ];
    } else if (nowInWhere.indexOf("network") != -1) {
      this.checkedLayers = [
        "'patrol_events'",
        "'patrol_equipments'",
        "'patrol_lines'",
        // "'patrol_networks_jihe'",
        "'user'",
      ];
    }
    let that = this;
    AMap.plugin(["AMap.AutoComplete"], function () {
      let autoOptions = {};
      that.autoComplete = new AMap.AutoComplete(autoOptions);
      that.selectLayer(that.checkedLayers);
    });
    this.EventListFun();
    this.PersonListFun();
    dicByType({ parentCode: "GAS_PATROL_EVENT", level: 2 }).then((res) => {
      if (res.code === 200) {
        for(let i=0;i<res.data.length;i++){
					let el=res.data[i]
					if(!el.children){
						res.data.splice(i,1)
						i=i-1
					}
				}
        this.EventTypeList = res.data;
      }
    });
  },
  methods: {
    setLnglat(lnglat) {
      this.lnglat = lnglat;
    },
    /**
     * 点击树
     */
    checkClick(val) {
      console.log(val);
    },
    SearchPersonFun() {
      this.PersonListFun();
    },
    DateChange() {
      this.SearchDataFun();
    },
    PersonClickFun(e) {
      if (e.location) {
        this.$parent.setCenter(e.location.split(" "));
        this.$parent.SetMarkerHighLight(e.location.split(" "));
        this.$parent.setUserPath(e);
      } else {
        this.$message.error("用户暂无位置信息");
      }
    },
    PersonListFun() {
      personList(this.PersonAjaxData).then((e) => {
        this.PersonList = e.data.records;
      });
    },
    EventClickFun(e) {
      this.$parent.setCenter(e.locations);
      this.$parent.SetMarkerHighLight(e.locations);
    },
    EventPageCurrentChange(e) {
      this.EventAjaxData.current = e;
      this.EventListFun();
    },
    EventListFun() {
      eventList(this.EventAjaxData).then((e) => {
        this.EventList = e.data.records;
        this.EventTotal = e.data.total;
      });
    },
    EventTypeChange(e) {
      this.EventAjaxData.eventType = e[1];
      this.SearchDataFun();
    },
    /**
     * 图层选中事件
     */
    selectLayer(val) {
      let layer = "";
      if (val && val.length > 0) {
        layer = val.toString();
        this.$eventBus.$emit("wmsLayer", layer);
      } else {
        this.$eventBus.$emit("clearLayer", layer);
      }
    },
    /**
     * 左侧按钮
     */
    ClickLeftMenu(e) {
      let that = this;
      if (e == 3) {
        // 图形树
        patrolGetDict({ pid: "524,518,70,46,512,517,527,5,528,532,533,558" }).then(
          (res) => {
            that.data = res.data;
            // 全选
            that.data.forEach((res) => {
              that.checkLayer.push(res.id);
            });
          }
        );
      }
      if(e== 2) {
         this.$eventBus.$emit('PersonListed',e)
      }
      if (this.SelectMenuData == e) {
        this.SelectMenuData = null;
        this.$emit("ClickMenu", e);
      } else {
        this.SelectMenuData = e;
        this.$emit("ClickMenu", e);
      }
    },
    SearchDataFun() {
      if (this.datePicker) {
        this.EventAjaxData.startTime = this.datePicker[0];
        this.EventAjaxData.endTime = this.datePicker[1];
      } else {
        this.EventAjaxData.startTime = "";
        this.EventAjaxData.endTime = "";
      }
      this.EventPageCurrentChange();
    },
    regoinChange(e) {
      let value = this.regionValue;
      if (value) {
        let [label, value] = this.regionValue.split("|");
        this.getRegionFeature(value);
      } else {
        this.$parent.map.remove(this.geojson);
      }
    },
    SearchCancel() {
      this.SearchResultArray = null;
    },
    SearchClick() {
      if (this.value) {
        this.autoComplete.search(this.value, (status, result) => {
          this.SearchResultArray = result.tips;
        });
      } else {
        this.SearchResultArray = [];
      }
    },
    getRegion() {
      let parames = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "gas:tsdivision",
        outputFormat: "application/json",
        propertyName: "NAME",
      };
      //FEATUREID
      regionList(parames).then((res) => {
        let feature = res.features;
        let option = []; // [{ label: '唐山', value: '' }]
        for (let i = 0; i < feature.length; i++) {
          let item = {
            label: feature[i].properties.NAME,
            value: feature[i].id,
          };
          option.push(item);
        }
        this.options = option;
      });
    },
    getRegionFeature(FEATUREID) {
      let that = this;
      let parames = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "gas:tsdivision",
        outputFormat: "application/json",
        FEATUREID,
      };
      //FEATUREID
      regionList(parames).then((res) => {
        if (this.geojson) {
          this.$parent.map.remove(this.geojson);
        }
        this.geojson = new AMap.GeoJSON({
          geoJSON: res,
          zIndexOffset: 1,
          getPolygon: function (geojson, lnglats) {
            return new AMap.Polygon({
              path: lnglats,
              fillOpacity: 0.2,
              strokeColor: "#45515d",
              fillColor: "#01d8ff",
            });
          },
        });
        this.geojson.on("click", function (e) {
          that.$parent.getFeatrue(e.lnglat);
        });
        this.$parent.map.add(this.geojson);
        this.$parent.map.setFitView(this.geojson._overlays);
      });
    },
    SearchItemClick(e) {
      if (this.hasMarker) {
        this.$parent.map.remove(this.marker);
      }

      this.hasMarker = true;
      let center = e.location;
      this.$parent.map.setCenter(center);
      this.SearchResultArray = null;
      this.marker = new AMap.Marker({
        position: center,
        content:
          '<div> <div class="scatterPoint scatterPoint2"></div>' +
          '<div class="scatterPoint scatterPoint3" ></div></div>',
        offset: new AMap.Pixel(0, 0),
      });
      this.$parent.map.add(this.marker);
      let that = this;
      setTimeout(() => {
        this.$parent.map.remove(this.marker);
        this.hasMarker = false;
      }, 60000);
    },
  },
};
</script>
<style lang="scss">
.popoList {
  span {
    display: block;
    padding: 0.1rem 0.13rem;
    cursor: pointer;
    color: var(--font-color-skin);
    i {
      color: var(--active-color-skin);
      padding-left: 3px;
      font-weight: bold;
    }
  }
  .spanS:active,
  .spanS:hover {
    background: var(--hover-color-skin);
  }
  .spanS {
    color: var(--active-color-skin);
  }
}
</style>
<style lang="scss" scoped>
.EventItemBody {
  margin-top: 20px;
}
.EventItemClass {
  cursor: pointer;
  padding: 5px 0;
}
.EventItemClass:hover {
  background-color: #eee;
}
.EventItemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 3px;
  border-bottom: 1px solid #eee;
}
.AddressDiv {
  padding: 10px;
}
.AddressDiv:hover {
  background: #f5f2f0;
}
// 图层弹框样式
.LeftFunction {
  padding: 20px;
  position: absolute;
  content: "";
  z-index: 999;
  left: 90px;
  top: 30px;
  background: #fff;
  width: 300px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  max-height: 500px;
  // overflow:auto;
}
.fatherLeftBox {
  position: absolute;
  content: "";
  z-index: 999;
  left: 10px;
  top: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.fatherLeftBox span {
  background: #fff;
  color: #666;
  text-align: center;
  margin-bottom: 5px;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.fatherLeftBox span:hover {
  color: white;
  background: #409eff;
}
.activedMenu {
  color: white !important;
  background: #409eff !important;
}
.EventItemBody::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: #ccc;
  border-color: transparent;
  border-width: 1.5px;
  background-clip: padding-box;
}
.EventItemBody::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}
.LeftFunction::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: #ccc;
  border-color: transparent;
  border-width: 1.5px;
  background-clip: padding-box;
}
.LeftFunction::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}
.popoverCon {
  padding: 10px;
  .el-checkbox {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .blockbt {
    display: block;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 0.7rem;
    line-height: 0.34rem;
    background: rgba(228, 228, 228, 0.1);
    border-radius: 0.05rem;
    cursor: pointer;
    color: var(--active-color-skin);
    box-shadow: 0px 0px 1px var(--shadow-color-skin) inset;
    text-align: center;
  }
  .blockbt.active {
    background: var(--background-c-color-skin);
  }
}
::v-deep {
  .borderno .el-input__inner {
    background: none;
    border: none;
    margin-left: 8px;
    font-size: 0.16rem;
    color: var(--font-b-color-skin);
    height: 32px;
    line-height: 32px;
  }
  .borderno .el-input__icon {
    line-height: 32px;
  }
  .borderno .el-input__suffix {
    height: 32px;
    line-height: 32px;
    right: auto;
    left: 0px;
  }
}
.searchBox {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  transition: left 0.5s;
  .inputBox {
    width: 2.67rem;
    border-radius: 0.22rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color-skin);
    border: 1px solid var(--border-color-skin);
    span {
      line-height: 24px;
      padding-right: 0.18rem;
      font-size: 0.2rem;
      color: var(--font-b-color-skin);
      cursor: pointer;
    }
  }

  .hide {
    border-top: 1px solid var(--border-color-skin);
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: var(--font-b-color-skin);
    margin-top: 0.1rem;
  }
  .SearchResultDataClass {
    position: absolute;
    width: 288px;
    background: var(--background-a-color-skin);
    border: 1px solid var(--border-color-skin);
    color: var(--font-color-skin);
    margin-top: 0.1rem;
    padding-top: 0.1rem;
    z-index: 10;
  }
  .SearchIconClass {
    height: 100%;
    width: 49px;
    background-size: 100% 100%;
    float: right;
    cursor: pointer;
  }
  .ResultItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    i {
      margin-left: 0.2rem;
      margin-right: 0.1rem;
    }
  }
}
.branch {
  font-size: 0.16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.2rem;
  min-width: 2.5rem;
  border-radius: 0.22rem;
  background: var(--background-color-skin);
  color: var(--font-b-color-skin);
  border: 1px solid var(--border-color-skin);
  .weather {
    height: 0.2rem;
    line-height: 0.2rem;
    width: 50%;
    text-align: center;
    border-left: 1px solid var(--border-color-skin);
  }
}
.dq {
  padding-left: 0.18rem;
  width: 1.5rem;
  cursor: pointer;
}
.simulation {
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  left: 0.1rem;
  li {
    width: 0.7rem;
    line-height: 0.4rem;
    background: var(--popover-color-skin);
    border-radius: 0.05rem;
    cursor: pointer;
    color: var(--active-color-skin);
    box-shadow: 0px 0px 2px var(--shadow-color-skin) inset;
    margin-top: 0.1rem;
    text-align: center;
    i {
      padding-right: 5px;
    }
  }
}
.EventItemClass {
  cursor: pointer;
  padding: 5px 0;
}
.EventItemClass:hover {
  background-color: #eee;
}
.EventItemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 3px;
  border-bottom: 1px solid #eee;
}
</style>
